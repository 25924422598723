import React from "react";
import { Link } from "gatsby"

function Footer() {

  return (
<footer className="w-full py-16 max-w-none px-3 mt-24 text-gray-800 bg-gray-300">
    <div className="flex w-full">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">I wanted to make finding a patio heater simpler, so my wife and I created this site to help you understand the options and see the best deals without having to spend days wrapping your head around the world of outdoor heaters! We hope it has helped.</p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="/terms-and-conditions/">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="/terms-and-conditions/">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Types of Heater</h2>
        <ul className="mt-4 leading-loose">
          <li>
          <Link to="/gas-heaters/">Gas Patio Heaters</Link>
          </li>
          <li>
          <Link to="/electric-heaters/">Electric Patio Heaters</Link>
          </li>
          <li>
          <Link to="/infrared-heaters/">Infrared Patio Heaters</Link>
          </li>
          <li>
          <Link to="/table-top/">Table Top Heaters</Link>
          </li>
          <li>
          <Link to="/free-standing/">Free-standing Patio Heaters</Link>
          </li>
          <li>
          <Link to="/wall-mounted/">Wall mounted Heaters</Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
  );
}


export default Footer;
